import {authAxios} from './../../config/axios';

class Range{
	get=(params=[])=>{
		return authAxios.get(`/administration/rangues`,{
			params
		});
	}

	show =(id)=>{
		return authAxios.get(`/administration/rangues/${id}`);
	}	

	showAdvance =(user_id)=>{
		return authAxios.get(`/plan/rangue/${user_id}/advance`);
	}
	update = (id,data)=>{
		return authAxios.put(`/administration/rangues/${id}`,data)
	}

	directBonds =(params=[])=>{
		return authAxios.get(`/plan/direct-bond`,{
			params
		});
	}

	directBondEdit =(id, data)=>{
		return authAxios.put(`/plan/direct-bond/${id}`,data);
	}

	getPrizes=(params=[])=>{
		return authAxios.get(`/plan/prize-rangue`,{
			params
		});
	}

	prizeEdit =(id, data)=>{
		return authAxios.put(`/plan/prize-rangue/${id}`,data);
	}

}

export default (new Range());
