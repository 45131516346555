import {authAxios} from './../../config/axios';

class User{

	save=(data)=>{
		return authAxios.post('/administration/requests',data);
	}

}

export default (new User());
