import {authAxios} from './../../config/axios';

class Blockchain{	
	swap=(params=[])=>{
		return authAxios.get(`/administration/blockchain/swap`,{
			params
		});
	}	

	transactions=(params=[])=>{
		return authAxios.get(`/administration/blockchain/transactions`,{
			params
		});
	}

	purchases=(params=[])=>{
		return authAxios.get(`/administration/blockchain/purchases`,{
			params
		});
	}	
}

export default (new Blockchain());
