import {authAxios} from './../../config/axios';

class Language{	
	get=(params=[])=>{
		return authAxios.get(`/plan/multilanguage`,{
			params
		});
	}

	show=(id)=>{
		return authAxios.get(`/plan/multilanguage/${id}`)
	}

	update=(id,data)=>{
		return authAxios.put(`/plan/multilanguage/${id}`,data)
	}

	save=(data)=>{
		let formData = new FormData();

		for(let k in data)
			formData.append(k,data[k]);

		return authAxios.post(`/plan/multilanguage`,formData);
	}
	
	delete=(id)=>{
		return authAxios.delete(`/plan/multilanguage/${id}`)
	}



}

export default (new Language());
