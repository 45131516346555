import {authAxios} from './../../config/axios';

class Newland{	
	get=(params=[])=>{
		return authAxios.get(`/plan/newland/stocks`,{
			params
		});
	}
	
	show=(id)=>{
		return authAxios.get(`/plan/newland/stocks/${id}`);
	}

	update=(id,data)=>{
		return authAxios.put(`/plan/newland/stocks/${id}`,data)
	}

	updateImage=(id,image)=>{
		let formData = new FormData();
		formData.append('image',image);
		return authAxios.post(`/plan/newland/stocks/${id}/image`,formData);
	}

	saveToken(id, data) {
		return authAxios.put(`/plan/newland/tokens/${id}`, data);
	}

	completed=(params=[])=>{
		return authAxios.get(`/plan/newland/tokens/completed`,{
			params
		});
	}

	pending=(params=[])=>{
		return authAxios.get(`/plan/newland/tokens/pending`,{
			params
		});
	}

	getFinancial  = (id, params=[])=>{
		return authAxios.get(`/plan/newland/stocks/${id}/financial`,{
			params
		});
	}
	getFinancialDetail  = (id, params=[])=>{
		return authAxios.get(`/plan/newland/stocks/${id}/financial-detail`,{
			params
		});
	}
	uploadFinancial =(id,document)=>{
		let formData = new FormData();
		formData.append('document',document);
		return authAxios.post(`/plan/newland/stocks/${id}/financial`,formData);
	}

	sendFinancial =(id)=>{
		return authAxios.post(`/plan/newland/stocks/${id}/send-financial`);
	}
	
}

export default (new Newland());
