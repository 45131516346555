import {authAxios} from './../../config/axios';

class Transactions{	
	get=(params=[])=>{
		return authAxios.get(`/plan/transactions`,{
			params
		});
	}

}

export default (new Transactions());
