import {authAxios} from './../../config/axios';
import documentApi from './document';

class Products{

	document ='';

	constructor() {  
	    this.document = documentApi;
	}

	get=(params=[])=>{
		return authAxios.get('/plan/products',{
			params
		});
	}

	save=(data)=>{
		return authAxios.post('/plan/products/save', data);
	}

	showMore=(id)=>{
		return authAxios.get(`/plan/products/${id}/show-more`);
	}

	update=(id,data)=>{
		return authAxios.put(`/plan/products/${id}`,data);
	}

	variants=(id)=>{
		return authAxios.get(`/plan/products/${id}/variants`);
	}


	nextContractId=(variant) =>{
		return authAxios.get(`/plan/products/next-contract-id/${variant}`);
	}

	setContractId=(data) =>{
		return authAxios.post(`/plan/products/set-contract-id`, data);
	}
}

export default (new Products());
