import {authAxios} from './../../config/axios';

class RangueHistory{	

	get=(params)=>{
		return authAxios.get(`/administration/rangue_histories`,{
			params
		});
	}



}

export default (new RangueHistory());

