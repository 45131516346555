/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import Votes from './votes'
import Challenges from './Challenges'
import ChallengesShow from './Challenges/Show'
import Static from '../Products/Statics';
import Discounts from './collectives/Discounts';
import Settings from './collectives/Settings';
import Events from './Events';
import EventsShow from './Events/show';
import Navbar from '../../components/Navbar';

export const CommunityIndex = () => {
  

  const routes= [
    {name:'Votos', 'route':'/comunidad'},
    {name:'Retos', 'route':'/comunidad/retos'},
    {name:'Colectivos', 'route':'/comunidad/colectivos'},
    {name:'Noticias', 'route':'/comunidad/noticias'},
  ]
  
  return (
    <>
    <Navbar routes={routes} />

    </>
  )
}

export const CommunityRoutes= [
  {path:'',  Element:Votes},
  {path:'retos',  Element:Challenges},
  {path:'retos/:id',  Element:ChallengesShow},
  {path:`colectivos/:id/descuentos`, Element:Discounts},
  {path:`colectivos/:id/configuracion`, Element:Settings},
  {path:'colectivos',  Element:Static, data:{
    slug:'collective'
  }},
  {path:`noticias`, Element:Events},
  {path:`noticias/:id`, Element:EventsShow},


]