/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import {Information} from './Show/information';
import {RangueAdvance} from './Show/RangueAdvance';
import { Purchases} from '../Purchases/';
import { Balances} from '../Balances/';
import { Wallet} from './Show/Wallet';
import { History} from './Show/History';
import { Merges} from './Show/Merge';
import { Tree} from '../Tree';
import Withdraws from '../Withdraw/withdraw';
import Navbar from '../../components/Navbar';
import {useParams} from 'react-router-dom';
import { Transaction } from '../transaction/local';
import  BondsPackage from './Show/Bonds/package';
import  BondsLoop from './Show/Bonds/loop';
import  BondsRange from './Show/Bonds/range';
import  BondsResidual from './Show/Bonds/residual';

export const UserShowIndex = () => {
  
  const {id} = useParams();

  const routes= [
    {name:'Informacion', 'route':`/usuarios/${id}`},
    {name:'Rango', 'route':`/usuarios/${id}/rango`},
    {name:'Historia', 'route':`/usuarios/${id}/historia`},
    {name:'Saldos', 'route':`/usuarios/${id}/saldos`},
    {name:'Compras', 'route':`/usuarios/${id}/compras`},
    {name:'Transacciones', 'route':`/usuarios/${id}/transacciones`},
    {name:'Retiros', 'route':`/usuarios/${id}/retiros`},
    {name:'Balances', 'route':`/usuarios/${id}/balances`},
    {name:'Fusiones de cuentas', 'route':`/usuarios/${id}/cuentas`},
    {name:'Referidos', 'route':`/usuarios/${id}/referidos`},
    {name:'Bonos Compras', 'route':`/usuarios/${id}/bonos/compras`},
    {name:'Bonos Loops', 'route':`/usuarios/${id}/bonos/loops`},
    {name:'Bonos rangos', 'route':`/usuarios/${id}/bonos/rango`},
    {name:'Bonos residual', 'route':`/usuarios/${id}/bonos/residual`},
  ]
  return (
    <>
    <Navbar routes={routes} />

    </>
  )
}


export const UserShowRoutes = [
  { path:"", Element:Information },
  { path:"rango", Element:RangueAdvance },
  { path:"saldos", Element:Wallet },
  { path:"historia", Element:History },
  { path:"compras", Element:Purchases },
  { path:"retiros", Element:Withdraws },
  { path:"balances", Element:Balances },
  { path:"cuentas", Element:Merges },
  { path:"transacciones", Element:Transaction },
  { path:"referidos", Element:Tree },
  { path:"bonos/compras", Element:BondsPackage },
  { path:"bonos/loops", Element:BondsLoop },
  { path:"bonos/rango", Element:BondsRange },
  { path:"bonos/residual", Element:BondsResidual },
]