import {authAxios} from './../../config/axios';

class User{
	get=(params=[])=>{
		return authAxios.get('/administration/users',{
			params
		});
	}

	show=(id)=>{
		return authAxios.get('/administration/users/'+id);
	}	

	save=(data)=>{
		return authAxios.post('/administration/users',data);
	}

	update=(id,data)=>{
		return authAxios.put(`/administration/users/${id}`,data);
	}	

	ban=(id)=>{
		return authAxios.post(`/administration/users/${id}/ban`);
	}	

	unban=(id)=>{
		return authAxios.post(`/administration/users/${id}/unban`);
	}

	unlock=(id)=>{
		return authAxios.post(`/administration/users/${id}/unlock`);
	}		
	
	delete=(id)=>{
		return authAxios.post(`/administration/users-delete/delete/${id}`);
	}	
	
	updateMine=(data)=>{
		return authAxios.put(`/administration/users/update`,data);
	}	


	aportation=(user_id)=>{
		return authAxios.get(`/plan/aportation/${user_id}`);
	}

	groupVolumen=(user_id)=>{
		return authAxios.get(`/plan/group-volume/${user_id}`);
	}

	directVolumen=(user_id)=>{
		return authAxios.get(`/plan/direct-volume/${user_id}`);
	}


	updateSponsor =(id, data) =>{
		return authAxios.post(`/administration/users/${id}/sponsor`, data);
	}


	//trash
	trash=(params=[])=>{
		return authAxios.get('/administration/users/trash',{
			params
		});
	}

		//trash
	unDelete=(id)=>{
		return authAxios.post(`/administration/users/${id}/unDelete`);
	}

	wallets = ()=>{
		return authAxios.get(`/administration/users/wallets`);
	}

	walletsUpdate = (data)=>{
		return authAxios.post(`/administration/users/walletsUpdate`, {
			wallets: JSON.stringify(data)
		});
	}

	walletsBalance = (params)=>{
		return authAxios.get(`/plan/wallets`, {
			params
		});
	}


}

export default (new User());
