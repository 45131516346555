export const colors = ['#FF663355', '#FFB39955', '#FF33FF55', '#FFFF9955', '#00B3E655', 
      '#E6B33355', '#3366E655', '#99996655', '#99FF9955', '#B34D4D55',
      '#80B30055', '#80990055', '#E6B3B355', '#6680B355', '#66991A55', 
      '#FF99E655', '#CCFF1A55', '#FF1A6655', '#E6331A55', '#33FFCC55',
      '#66994D55', '#B366CC55', '#4D800055', '#B3330055', '#CC80CC55', 
      '#66664D55', '#991AFF55', '#E666FF55', '#4DB3FF55', '#1AB39955',
      '#E666B355', '#33991A55', '#CC999955', '#B3B31A55', '#00E68055', 
      '#4D806655', '#80998055', '#E6FF8055', '#1AFF3355', '#99993355',
      '#FF338055', '#CCCC0055', '#66E64D55', '#4D80CC55', '#9900B355', 
      '#E64D6655', '#4DB38055', '#FF4D4D55', '#99E6E655', '#6666FF55'];


export const colorsBorder = ['#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6', 
      '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
      '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A', 
      '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
      '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC', 
      '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
      '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680', 
      '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
      '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3', 
      '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'];