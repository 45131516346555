import {  Stack,  SimpleGrid, Text, Button} from '@chakra-ui/react'
import React, { useEffect, useState, useCallback } from 'react'
import {userApi} from '../../../api';
import {useParams, useNavigate} from 'react-router-dom';
import DataTables from '../../../components/Datatable';
import { getUserWalletsAsync } from '../../../redux/user/actions'

export const Wallet = () => {

    const {id} = useParams();
    const [user,setUser] = useState(false);
    const navigate = useNavigate();

    useEffect(()=>{
      userApi.show(id).then((res)=>{
        setUser(res.data.data);
      })
    },[id])

    const [saldos, setSaldos] = useState({
      aportation:0,
    })


    const getInfo=useCallback(()=>{
        user?.id && userApi.aportation(user.id).then(response=>{
          setSaldos(s=>({
            ...s,
            aportation:response.data.data
          }))
         })

        user?.id && userApi.groupVolumen(user.id).then(response=>{
          setSaldos(s=>({
            ...s,
            groupVolumen:response.data.data
          }))
         })
        
        user?.id && userApi.directVolumen(user.id).then(response=>{
          setSaldos(s=>({
            ...s,
            directVolumen:response.data.data
          }))
         })
    }, [user]);

     useEffect(()=>{
       user && getInfo();
    },[getInfo, user])



  const selectable = (state)=>(state.user.wallets);

  const columns = (row)=>{
    return {
      movement: row?.movement,
      balance: row?.balance,
      compra: row?.purchase_id ? <Button onClick={()=>navigate('/purchases/'+row.purchase_id)}>Ver Compra</Button> : '-',
    } 
  }

  const dispatchable=(query)=>{
    return getUserWalletsAsync({
      ...query,
      user_id: id,
    });
  } 


    return (
        <Stack  bg={"white"}>    

      <SimpleGrid columns={[2, null, 4]} style={{marginTop:'30px'}} spacing='30px'>
    
        <Stack p="4" boxShadow="lg" m="2" style={{textAlign:'center','position':'relative'}} borderRadius="sm">
            <Text fontWeight="semibold">Depositos antiguos</Text>
            <Text fontWeight="bold">$ {user?.metadata?.saldo || 0}</Text>   
        </Stack>

        <Stack p="4" boxShadow="lg" m="2" style={{textAlign:'center','position':'relative'}} borderRadius="sm">
            <Text fontWeight="semibold">Comisiones antiguas</Text>
            <Text fontWeight="bold">$ {user?.metadata?.comission || 0}</Text>   
        </Stack>
        <Stack p="4" boxShadow="lg" m="2" style={{textAlign:'center','position':'relative'}} borderRadius="sm">
            <Text fontWeight="semibold">Aportacion</Text>
            <Text fontWeight="bold">$ {saldos?.aportation || 0}</Text>   
        </Stack>



        <Stack p="4" boxShadow="lg" m="2" style={{textAlign:'center','position':'relative'}} borderRadius="sm">
            <Text fontWeight="semibold">Volumen grupal real</Text>
            <Text fontWeight="bold">$ {saldos?.groupVolumen || 0}</Text>   
        </Stack>

        <Stack p="4" boxShadow="lg" m="2" style={{textAlign:'center','position':'relative'}} borderRadius="sm">
            <Text fontWeight="semibold">Volumen directo real</Text>
            <Text fontWeight="bold">$ {saldos?.directVolumen || 0}</Text>   
        </Stack>
           
      </SimpleGrid>
   

           <DataTables  
          columns={columns}
          dispatchable={dispatchable}
          selectable={selectable}
          title='Gestion de saldo'
        />
      

        
        </Stack>

       

    )
}
