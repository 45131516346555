import React , {useEffect, useState} from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { Stat, Box, StatLabel } from '@chakra-ui/react'
import {colors, colorsBorder} from './colors';

ChartJS.register(ArcElement, Tooltip, Legend);

export default function Packages ({info}) {

  
  const [data, setData] = useState({datasets:[],labels:[]});

  useEffect(()=>{

    let labels = Object.keys(info.products);
    let amount = Object.values(info.products)
      setData({
        labels,
        datasets:[{
          data:amount,
          backgroundColor: colors,
          borderColor:colorsBorder,
          borderWidth: 1,         
        }]

      })
  },[info]);



  return (
    <Stat>
        <Box  borderWidth='1px' padding="10px" borderRadius="8px">
        <StatLabel>{info.name || '...cargando'}</StatLabel>
            <Pie data={data} />
        </Box>
    </Stat>
    )
}
