import React, {useState, useEffect} from 'react'
import {getBalanceHistoryAsync} from '../../../../../redux/purchase/actions';
import DataTables from '../../../../../components/Datatable';
import {Confirm} from '../../../../../components/buttons';
import {packageApi} from '../../../../../api';
import {toast} from 'react-toastify';
import {Modal, OpenModal}  from '../../../../../components/Modal';
import Builder from '../../../../../components/forms';
import { useDisclosure } from '@chakra-ui/react'


export default function Balances({purchase}) {   

  const disclousure = useDisclosure();


  const [edit, setEdit] = useState({});
  const [fields, setFields] = useState([]);


  useEffect(()=>{

    edit.id && setFields([
    {
      name:'balance',
      label:'Balance',
      required:true,
      defaultValue:edit.balance,
    },
    {
      name:'movement',
      label:'Movimiento',
      required:true,
      defaultValue:edit.movement,
    },    
    {
      name:'profit',
      label:'Ganancia al dia',
      required:true,
      defaultValue:edit.profit,
    },    
    {
      name:'comission',
      label:'Comision',
      required:true,
      defaultValue:edit.comission,
    },
    ])

  }, [edit])
  const dispatchable = (query)=>{
    return getBalanceHistoryAsync(purchase.multiplier.id, {
      ...query,
    });
  }


   const selectable = (state)=>(state.purchase.balance);


      const columns = (row)=>{

        let extra = {};

        if(purchase.multiplier.scheme===2) {
          extra.invitacion_total = `${row.comission_profit} ${row.currency}`;
        }
        
        return {
          balance : `$ ${row.balance} ${row.currency}`,
          movimiento : `$ ${row.movement} ${row.currency}`,
          ganancia_al_dia : `$ ${row.profit} ${row.currency}`,
          invitacion : `$ ${row.comission} ${row.currency}`,
          ...extra,
          date: new Date(row.date).toLocaleString(),
          _: row.last ? <OpenModal  disclousure={disclousure} onClick={()=>setEdit(row)} text="corregir" /> : null
        }
      } 


    const roi = ()=>{

      if(purchase.multiplier.generate_roi===0) {
         toast.warning("Este paquete no genera ROI", {"theme":"dark"})
         return;
      }
      packageApi.nextRoi(purchase.multiplier.id).then(res=>{
        toast.success("ROI Generado correctamente", {"theme":"dark"})
        window.location.reload(false);
      })
    }


  const saveBalance=(data)=>{
    packageApi.updateBalance(edit.id, data).then(res=>{
        toast.success("Balance actualizado correctamente", {"theme":"dark"})
        window.location.reload(false);
    })
  }

  return (
<>
          <Confirm text="Adelantar roi" onClick={roi}/>
                <Modal
          isOpen={disclousure.isOpen}
          onClose={disclousure.onClose}

      >
        <Builder fields={fields} onClick={saveBalance} />
            
      </Modal>

          <DataTables  
            columns={columns}
            dispatchable={dispatchable}
            selectable={selectable}
            title='Balance'
          />
</>
  );
}