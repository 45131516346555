import {authAxios} from './../../config/axios';

class Withdraws{	

	get=(params=[])=>{
		return authAxios.get('/plan/withdraws/view',{
			params
		});
	}

	download=(params=[])=>{
		return authAxios.get('/plan/withdraws/download',{
			params
		});
	}

	total=(data)=>{
		return authAxios.post('/plan/withdraws/total', data);		
	}

	payments=(data)=>{
		return authAxios.post('/plan/withdraws/payments',data);		
	}

	saveTransactions=(data)=>{
		return authAxios.post('/plan/withdraws/paid', data);		
	}

	types=()=>{
		return authAxios.get('/plan/withdraws/types');
	}

	cancel=(id)=>{
		return authAxios.put(`/plan/withdraws/${id}/Cancelado`);
	}

	complete=(id)=>{
		return authAxios.put(`/plan/withdraws/${id}/Completado`);
	}
}

export default (new Withdraws());

