import {authAxios} from './../../config/axios';

class Range{
	newUsers=(start, end)=>{
		let formData = new FormData();
		formData.append('minDate',start);
		formData.append('maxDate',end);

		return authAxios.post(`/administration/reports/register`,formData);
	}

	sells=(start, end)=>{
		let formData = new FormData();
		formData.append('minDate',start);
		formData.append('maxDate',end);

		return authAxios.post(`/plan/reports/sells`,formData);
	}
	
	invite=(start, end)=>{
		let formData = new FormData();
		formData.append('minDate',start);
		formData.append('maxDate',end);
		formData.append('limit',10);

		return authAxios.post(`/administration/reports/invite`,formData);
	}


	ranges=(start, end)=>{
		let formData = new FormData();
		formData.append('minDate',start);
		formData.append('maxDate',end);

		return authAxios.post(`/administration/reports/rangues`, formData);
	}

	packages=(start, end)=>{
		let formData = new FormData();
		formData.append('minDate',start);
		formData.append('maxDate',end);

		return authAxios.post(`/plan/reports/plans`, formData);
	}

	totalPackages=(start, end)=>{
		let formData = new FormData();
		formData.append('minDate',start);
		formData.append('maxDate',end);

		return authAxios.post(`/plan/reports/plans/total`, formData);
	}

	allSells =(start, end)=>{
		let formData = new FormData();
		formData.append('minDate',start);
		formData.append('maxDate',end);

		return authAxios.post(`/plan/reports/allSells`, formData);
	}
	userWallet=()=>{

		return authAxios.post(`/administration/reports/user/wallet`);
	}	

	usersPackage=()=>{

		return authAxios.post(`/plan/reports/users/package`);
	}


	listPackages=(start, end)=>{
		let formData = new FormData();
		formData.append('minDate',start);
		formData.append('maxDate',end);

		return authAxios.post(`/plan/reports/listPackages`, formData);
	}
}


export default (new Range());
