import {bondApi} from '../../api';
import types from './actionTypes';



export const getPackageAsync = (params) => async (dispatch) => {
    const response = await bondApi.multiplierPackage(params);

    if(params['charged']===0) {
        dispatch(types.multiplier.package.getPending(response.data.data))
    } else if (params['charged']===1) {
        dispatch(types.multiplier.package.getCompleted(response.data.data))
    }
}

export const getLoopAsync = (params) => async (dispatch) => {
    const response = await bondApi.multiplierLoop(params);

    if(params['charged']===0) {
        dispatch(types.multiplier.loop.getPending(response.data.data))
    } else if (params['charged']===1) {
        dispatch(types.multiplier.loop.getCompleted(response.data.data))
    }
}


export const getRangeAsync = (params) => async (dispatch) => {
    const response = await bondApi.multiplierRange(params);
    console.log('res', response);

    if(params['charged']===0) {
        dispatch(types.multiplier.range.getPending(response.data.data))
    } else if (params['charged']===1) {
        dispatch(types.multiplier.range.getCompleted(response.data.data))
    }
}

export const getResidualAsync = (params) => async (dispatch) => {
    const response = await bondApi.multiplierResidual(params);
    console.log('res', response);

    if(params['charged']===0) {
        dispatch(types.multiplier.residual.getPending(response.data.data))
    } else if (params['charged']===1) {
        dispatch(types.multiplier.residual.getCompleted(response.data.data))
    }
}


