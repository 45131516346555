import {authAxios} from './../../config/axios';

class Review{
	get=(product_id,params=[])=>{
		return authAxios.get(`/plan/products/${product_id}/reviews`,{
			params
		});
	}

	hidden=(id)=>{
		return authAxios.get(`/plan/products/reviews/${id}/hidden`)
	}
	
}

export default (new Review());
