import {authAxios} from './../../config/axios';

class Tree{
	get=()=>{
		return authAxios.get('/administration/dashboard/network');
	}	

	search=(id)=>{
		return authAxios.get('/administration/dashboard/network/'+id);
	}	

	download=(data)=>{
		return authAxios.post('/administration/dashboard/network/download',data);
	}			
}

export default (new Tree());
