import {authAxios} from './../../config/axios';

class Packages{	

	balance=(id)=>{
		return authAxios.get(`/plan/balance/${id}`);
	}

	balanceHistory=(id, params)=>{
		return authAxios.get(`/plan/balance/${id}/history`,{
			params
		});
	}

	balancesUser=(id, params)=>{
		return authAxios.get(`/plan/balance/${id}/user`,{
			params
		});
	}

	withdraws = (id, data) =>{
		return authAxios.post(`/plan/balance/${id}/withdraw-admin`, data);
	}
	
	updateBalance = (id, data) =>{
		return authAxios.put(`/plan/balance/${id}`, data);
	}
		
	nextRoi = (id) =>{
		return authAxios.post(`/plan/balance/${id}/nextRoi`);
	}

}

export default (new Packages());

