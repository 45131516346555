import { ethers } from "ethers";

class Usdt {

	constructor() {
	    return (async () => {

	    	const provider = new ethers.providers.Web3Provider(window.ethereum);
	    	this.signer = provider.getSigner();

		    this.swapAddress = process.env.REACT_APP_USDT_ADDRESS; //swap  contract address

		    const ABI = ["function balanceOf(address account) view returns (uint)"];

			this.swapContract = new ethers.Contract(this.swapAddress, ABI, this.signer);

		    return this;
	    })();
	  }


	balance(){
		return this.swapContract.balanceOf(process.env.REACT_APP_SWAP_ADDRESS);
	}

}

export default Usdt;