import {authAxios} from './../../config/axios';

class Purchases{	
	get=(params=[])=>{
		return authAxios.get(`/plan/purchases`,{
			params
		});
	}

	show=(id)=>{
		return authAxios.get(`/plan/purchase/${id}`);
	}

	makePurchaseFree=(data)=>{
		return authAxios.post('/plan/purchase/free', data);
	}

	register=(data)=>{
		return authAxios.post('plan/purchase/admin', data);
	}

	comissions= (id)=>{
		return authAxios.get(`/plan/purchase/${id}/comissions`);
	}

	setComissions= (id)=>{
		return authAxios.post(`/plan/purchase/${id}/comissions`);
	}
}

export default (new Purchases());
