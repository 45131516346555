import {authAxios} from './../../config/axios';

class Chaincrop{	
	get=(params=[])=>{
		return authAxios.get(`/plan/chaincrop/stocks`,{
			params
		});
	}
	
	show=(id)=>{
		return authAxios.get(`/plan/chaincrop/stocks/${id}`);
	}

	update=(id,data)=>{
		return authAxios.put(`/plan/chaincrop/stocks/${id}`,data)
	}

	updateImage=(id,image)=>{
		let formData = new FormData();
		formData.append('image',image);
		return authAxios.post(`/plan/chaincrop/stocks/${id}/image`,formData);
	}

	saveToken(id, data) {
		return authAxios.put(`/plan/chaincrop/tokens/${id}`, data);
	}

	completed=(id,params=[])=>{
		return authAxios.get(`/plan/chaincrop/tokens/${id}/completed`,{
			params
		});
	}

	pending=(id,params=[])=>{
		return authAxios.get(`/plan/chaincrop/tokens/${id}/pending`,{
			params
		});
	}

	getFinancial  = (id, params=[])=>{
		return authAxios.get(`/plan/chaincrop/financial/${id}`,{
			params
		});
	}
	getFinancialDetail  = (id, params=[])=>{
		return authAxios.get(`/plan/chaincrop/stocks/${id}/financial-detail`,{
			params
		});
	}
	saveFinancial =(id,data)=>{;
		return authAxios.post(`/plan/chaincrop/financial/${id}`,data);
	}

	sendFinancial =(id)=>{
		return authAxios.post(`/plan/chaincrop/stocks/${id}/send-financial`);
	}
	
}

export default (new Chaincrop());
