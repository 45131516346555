/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import Total from './total';

import Navbar from '../../components/Navbar';

export const WithdrawIndex = () => {
  
  const routes= [
    {name:'Retiros', 'route':'/retiros'},
  ]
  
  return (
    <>
    <Navbar routes={routes} />

    </>
  )
}

export const WithdrawRoutes= [
  {path:'',  Element:Total},

]