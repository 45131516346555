/* eslint-disable react-hooks/exhaustive-deps */
import { Stack, Button } from '@chakra-ui/react'
import React from 'react'
import DataTables from '../../../../../components/Datatable';
import {getPackageAsync} from '../../../../../redux/bond/actions';
import moment from 'moment';
import {useNavigate} from 'react-router-dom';


export const Pending = ({user_id=null}) => {
  
  const navigate= useNavigate();

  const selectable = (state)=>(state.bond.multiplier.package.pending);

  const columns = (row)=>{
    return {
     '%': row?.porcent,
     bono : row?.bond,
     compra: <Button onClick={()=>navigate('/ventas/'+row.purchase_id)}>Ver compra</Button>,
     fecha_creacion : moment(row?.created_at || 'Y-m-d').format('YYYY-MM-D H:M'),
    }
  } 


  const dispatchable=(query)=>{

    if (user_id!= null) {
      return getPackageAsync({
        ...query,
        user_id,
        charged:0,
      });
    }

    return getPackageAsync({
      ...query,
      charged:0,
    });
  } 

  return (
    <Stack style={{ display: 'flex', flexDirection: 'row' }} bg={'white'}>
     <DataTables  
          columns={columns}
          dispatchable={dispatchable}
          selectable={selectable}
          title='Bonos listos para retirar'
      />
    </Stack>  
  )
}
