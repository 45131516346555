import {authAxios} from './../../config/axios';

class Role{
	get=(params)=>{
		return authAxios.get('/administration/roles', {
			params
		});
	}	

	save=(data)=>{
		return authAxios.post('/administration/roles', data);
	}

	delete=(id)=>{
		return authAxios.delete(`/administration/roles/${id}`);
	}		
}

export default (new Role());
