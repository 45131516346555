/* eslint-disable react-hooks/exhaustive-deps */
import {  SimpleGrid,Box } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import Builder from '../../../../components/forms';
import { useParams, useNavigate} from 'react-router-dom';
import { planApi } from '../../../../api';
import { toast } from 'react-toastify';

export const PlansCreate = () => {

 
  const {id} = useParams();
  const [fields,setFields]= useState([]);
  const navigate = useNavigate();

  useEffect(()=>{

      setFields([
        {
          name:'name',
          label:'Nombre',
          required:true,
          defaultValue:'',
        },
        {
          name:'image',
          label:'Imagen',
          required:true,
          file:true,
        },   
        {
          name:'description',
          label:'Descripcion',
          required:true,
          defaultValue:'',
        },     
        {
          name:'content',
          label:'Contenido',
          required:true,
          editor:true,
          defaultValue:'',
        },
        {
          name:'price',
          label:'Precio',
          required:true,
          defaultValue:'',
        },
        {
          name:'currency',
          label:'MONEDA',
          required:true,
          defaultValue:'',
        },
        {
          name:'stock',
          label:'Stock',
          required:true,
          defaultValue:'',
        },
        {
          name:'interest',
          label:'Interes compuesto',
          required:true,
          defaultValue:'',
        },
        {
          name:'automatically_ends',
          label:'Retorno final',
          required:true,
          defaultValue:'',
        }
      ]);

  },[])

 


  const savePlan = (data)=>{
    data.product_id=id;
    planApi.save(data).then(res=>{
      toast.success("Plan creado correctamente", {"theme":"dark"});
      navigate(`/planes/${res.data.data.id}/editar`)
    });

  }

    return (
     <>
        <Box p={5} shadow='md' borderWidth='1px' >
      <SimpleGrid columns={2} spacingX='40px' spacingY='20px'>



        <Builder fields={fields}   message='Crear plan' onClick={savePlan} />
         
         </SimpleGrid>
         </Box>
        
     </>
    )
  
}


