import { Stack } from '@chakra-ui/react'
import React, {useState, useEffect} from 'react'
import {addWallets} from '../../config/blockchain/swap/actions';
import {Confirm} from '../../components/buttons';
import {userApi} from '../../api';
const style= {
  container:{
    display:'flex',
    flexDirection:'column',
    border:'1px solid #eaeaeb',
    marginTop:'1rem',
    padding:'1rem',
  },
  h1:{
    fontWeight:'bold',
    fontSize:24
  }
}
export const SwapWallets = () => {

    const [wallets, setWallets] = useState([]);

    useEffect(()=>{
      loadWallets()
    },[])

    const loadWallets = ()=>{
    
      userApi.wallets().then(res=>{
        setWallets(res.data.data)
      })
    

    }

    
    const confirmWallets = ()=>{
      addWallets(wallets, userApi.walletsUpdate).then(loadWallets)
    }
    return (
      <Stack style={style.container} bg={'white'} w={'100%'}>
      <h1 style={style.h1}>Recargar wallets </h1>
      <p>{wallets.length} nuevas wallets</p>

       <Confirm enabled={wallets.length} onClick={confirmWallets}  title="Agregar wallets" />
       </Stack>
       )
 }
