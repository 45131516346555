import {authAxios} from './../../config/axios';

class Bonds{	

	multiplierPackage=(params=[])=>{
		return authAxios.get('/plan/bonds/multiplier/package',{
			params
		});
	}

	multiplierLoop=(params=[])=>{
		return authAxios.get('/plan/bonds/multiplier/loop',{
			params
		});
	}

	multiplierRange=(params=[])=>{
		return authAxios.get('/plan/bonds/multiplier/rangue',{
			params
		});
	}
	multiplierResidual=(params=[])=>{
		return authAxios.get('/plan/bonds/multiplier/residual',{
			params
		});
	}

	total=()=>{
		return authAxios.get('/plan/bonds/multiplier/total');
	}
}

export default (new Bonds());

