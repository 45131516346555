import {authAxios} from './../../config/axios';

class Version{


	save=(data)=>{
		return authAxios.post(`/plan/products/versions/save`,data);
	}
	get=(product_id,params=[])=>{
		return authAxios.get(`/plan/products/${product_id}/versions`,{
			params
		});
	}

	delete=(id)=>{
		return authAxios.delete(`/plan/products/versions/${id}`);
	}


	
}

export default (new Version());
