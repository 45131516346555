import React , {useEffect, useState, useCallback} from 'react';
import {summaryApi} from '../../api';
import moment from 'moment';
import {toast} from 'react-toastify';
import { Table, TableCaption, Thead, Tr, Th, Td, Input,SimpleGrid, Button,
  Stat,
  StatLabel,
  StatNumber,
  Box,
}  from '@chakra-ui/react'
import {useNavigate} from 'react-router-dom';

export default function ListPackage () {

    const navigate = useNavigate();
    const [start, setStart] = useState(moment().startOf('week').toISOString().split('T')[0]);
    const [end, setEnd] = useState(moment().toISOString().split('T')[0]);
    
    const [sells, setSells] = useState([]);


   const handleStart= (e)=>{
        setStart(e.target.value.toString())

    }

    const handleEnd= (e)=>{
        setEnd(e.target.value.toString())
    }

    const onSearch = useCallback(()=>{

      summaryApi.listPackages(start,end).then(res=>{
        setSells(res.data.data)

      })  

      toast.info("...Buscando",{"theme":"dark",autoClose:1000})

    }, [end, start]);

    useEffect(()=>onSearch(),[onSearch]);
 
const renderTable=()=>{

    let table = [];
    for (let k in sells.users){

        table.push(<Tr>
            <Td  key={'thead-tr-1'}>{sells.users[k]}</Td>
            <Td  key={'thead-tr-2'}><Button onClick={()=>navigate('/usuarios/'+k)}>Ver usuario</Button></Td>
        </Tr>)
    }

    return table
}
 

  return (
    <>
        <SimpleGrid columns={3} spacing={10}>
        <Input
         placeholder="Select Date and Time"
         size="md"
         type="date"
        onChange={handleStart}
        defaultValue={start}

        />
                <Input
         placeholder="Select Date and Time"
         size="md"
         defaultValue={end}
         type="date"
         onChange={handleEnd}
        />

          <Button onClick={onSearch}>Buscar</Button>

        </SimpleGrid>


        <SimpleGrid marginTop="16px" columns={4} spacing={10} >

            <Stat>
               <Box  borderWidth='1px' padding="10px" borderRadius="8px">
              <StatLabel>Vendido</StatLabel>
              <StatNumber>USD ${sells.price || 0}</StatNumber>
              </Box>
            </Stat>

            <Stat>
               <Box  borderWidth='1px' padding="10px" borderRadius="8px">
              <StatLabel>ROI Generado</StatLabel>
              <StatNumber>USD ${sells.profit || 0}</StatNumber>
              </Box>
            </Stat>
            <Stat>
               <Box  borderWidth='1px' padding="10px" borderRadius="8px">
              <StatLabel>Disponible</StatLabel>
              <StatNumber>USD ${sells.balance || 0}</StatNumber>
              </Box>
            </Stat>


               <Stat>
               <Box  borderWidth='1px' padding="10px" borderRadius="8px">
              <StatLabel>Retirado</StatLabel>
              <StatNumber>USD ${sells.balance || 0}</StatNumber>
              </Box>
            </Stat>


            <Stat>
               <Box  borderWidth='1px' padding="10px" borderRadius="8px">
              <StatLabel>ROI Estimado total (puede aumentar)</StatLabel>
              <StatNumber>USD ${sells.automatically_ends || 0}</StatNumber>
              </Box>
            </Stat>


            <Stat>
               <Box  borderWidth='1px' padding="10px" borderRadius="8px">
              <StatLabel>ROI Por generar (puede aumentar)</StatLabel>
              <StatNumber>USD ${sells.expected || 0}</StatNumber>
              </Box>
            </Stat>

            <Stat>
               <Box  borderWidth='1px' padding="10px" borderRadius="8px">
              <StatLabel>Usuarios</StatLabel>
              <StatNumber>{Object.keys(sells.users || {}).length || 0}</StatNumber>
              </Box>
            </Stat>
        </SimpleGrid>
  



            <Table variant='simple'>
                <TableCaption
                    color={'brand.initialBackground'}
                    fontSize={12}
                    fontWeight={'500'}
                  >Usuarios a terminar</TableCaption>
                <Thead>
                    <Tr>
                        <Th  key={'thead-tr-1'}>usuario</Th>
                        <Th  key={'thead-tr-2'}></Th>
                    </Tr>
                </Thead>
                <tbody>
                  { sells && renderTable()}
                </tbody>
            </Table>
      </>
    )
}
