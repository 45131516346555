import {authAxios} from './../../config/axios';

class Merges{
	get=(user_id, params=[])=>{
		return authAxios.get(`/administration/merges/${user_id}/user`,{
			params
		});
	}
}

export default (new Merges());
