import {authAxios} from './../../config/axios';

class Categories{
	get=(params=[])=>{
		return authAxios.get('/plan/categories',{
			params
		});
	}

	show=(id)=>{
		return authAxios.get('/plan/categories/'+id);
	}	

	save=(data)=>{
		let formData = new FormData();
		for (let k in data)
			formData.append(k,data[k]);

		return authAxios.post(`/plan/categories/save`,formData);

	}
	updateImage=(id, data)=>{
		let formData = new FormData();
		for (let k in data)
			formData.append(k,data[k]);

		return authAxios.post(`/plan/categories/${id}/image`,formData);
	}

	delete=(id)=>{
		return authAxios.delete(`/plan/categories/${id}`);
	}

	update=(id,data)=>{
		return authAxios.put(`/plan/categories/${id}`,data);
	}	
	
}

export default (new Categories());
