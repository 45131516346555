import {toast} from 'react-toastify';
import Swap from './Swap';
import Nst from './Nst';
import Usdt from './Usdt';

const runSwap =  async ()=>{

  	const SwapContract = await (new Swap());

  	try {
  		await SwapContract.enableNstSell();
  		toast.success("Swap activado", {theme:"dark"});
  	} catch(e) {
  		console.log(e);
  	}   
}

const disableSwap =  async ()=>{

  	const SwapContract = await (new Swap());

  	try {
  		await SwapContract.disableNstSell();
  		toast.success("Swap desactivado", {theme:"dark"});
  	} catch(e) {
  		console.log(e);
  	}   
}

const showBalance = async()=>{
    const nstContract =  await (new Nst());
    const usdtContract = await (new Usdt());

	try {
      let nsts= Number(await nstContract.balance())/10000;
      let usdts = Number(await usdtContract.balance())/1000000;
      console.log(nsts,usdts);
      
      nsts = 'NST '+nsts.toLocaleString("en", {
			style: "currency",
			currency: "USD"
		})

      usdts = 'USDT '+usdts.toLocaleString("en", {
		style: "currency",
		currency: "USD"
	})
      return {
      	nsts,
      	usdts,
      };
	}catch (e) {
        toast.error('Hubo un error', {'theme':"dark"});
      console.error(e);
      throw e;
    }
}


const withdrawFunds = async()=>{
	const swapContract = await (new Swap());

	try {
      console.log("Withdrawing funds...");
      const tx = await swapContract.withdrawPair();
      tx.wait();
      console.log("Transaction hash: " + tx.hash);
      toast.success('Retirado correctamente', {'theme':"dark"});
      alert(tx.hash)
    } catch (e) {
        toast.error('Hubo un error', {'theme':"dark"});
      console.error(e);
    }
}


const addWallets = async(wallets, fn)=>{
	const swapContract = await (new Swap());

	try {
      const tx = await swapContract.addUser(wallets);
      const res = await fn(wallets);
      console.log(res);
      toast.success('Wallets registradas correctamente', {'theme':"dark"});
      console.log(tx)
    } catch (e) {
    	toast.error('Hubo un error', {'theme':"dark"});
     	console.error(e);
    }
}


export {
	runSwap,
	disableSwap,
	showBalance,
	withdrawFunds,
	addWallets
}