/* eslint-disable react-hooks/exhaustive-deps */
import { Tabs, TabList, TabPanels, TabPanel, Tab } from '@chakra-ui/react'
import React from 'react'
import {Pending} from './pending';
import {Completed} from './completed';
import {useParams} from 'react-router-dom';

export default function Package()  {
  
  const {id} = useParams();

  return (
    <>
      <Tabs variant='soft-rounded' colorScheme='green'>
        <TabList>
          <Tab>Pendiente</Tab>
          <Tab>Solicitado</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
           <Pending user_id={id} / > 
        </TabPanel>
          <TabPanel>
             <Completed user_id={id} / > 
       
        </TabPanel>


        </TabPanels>
      </Tabs>
      </>
  )
}
