import {authAxios} from './../../config/axios';

class Collective{
	get=(params=[])=>{
		return authAxios.get('/plan/collective',{
			params
		});
	}


	save=(data)=>{
		let formData = new FormData();
		for (let k in data)
			formData.append(k,data[k]);

		return authAxios.post(`/plan/collective`,formData);

	}
	
	delete=(id)=>{
		return authAxios.delete(`/plan/collective/${id}`);
	}

	getDiscounts=(params=[])=>{
		return authAxios.get('/plan/collective/discount',{
			params
		});
	}


	saveDiscount=(data)=>{
		let formData = new FormData();
		for (let k in data)
			formData.append(k,data[k]);

		return authAxios.post(`/plan/collective/discount`,formData);

	}
	
	
	deleteDiscount=(id)=>{
		return authAxios.delete(`/plan/collective/discount/${id}`);
	}

	
}

export default (new Collective());
